import { createStore, combineReducers } from 'redux';
import { reducer as jPlayers } from 'react-jplayer';
import { reducer as jPlaylists } from 'react-jplaylist';
import global from './reducers/global'


/* Pass the jPlaylist reducer and it's initialStates to the store */
const store = createStore(combineReducers({ 
    jPlayers, 
    jPlaylists,
    global
}));




export default store;