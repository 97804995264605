import React, { useEffect, useState } from 'react'
import Carrusel from '../components/Carrusel'
import Iframe from 'react-iframe'
import {  Hidden } from '@material-ui/core'
import baseURL from '../axios/url'

const Auspiciadores = (props) => {
    const auspiciadores = props.auspiciadores;
    const imgMarca = props.image.marca;
    const [data,setData]=useState({
        a1:[],
        a2:[],
        a3:[],
        a4:[],
        a5:[],
    })
    useEffect(()=>{
        
        const total = auspiciadores.length;
        let partes = Math.floor(total/5)
        if(partes ===0) partes = 1; 
       setData({
        a1:auspiciadores.slice(0,partes),
        a2:auspiciadores.slice(partes,2*partes),
        a3:auspiciadores.slice(2*partes,3*partes),
        a4:auspiciadores.slice(3*partes,4*partes),
        a5:auspiciadores.slice(5*partes,total),
       })
    },[])

 
 
 
    return (
       <>
        <Hidden smDown>
            <div className="auspiciadores">
           <div className="content">
           <div style={{ height: "150px", marginTop: "6rem" }}>
                <Iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fciudad91&tabs=like&width=280&height=350&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2933012026780696"
                    className="facex"
                    scrolling="no"
                    frameborder="0"
                    allowTransparency="true"
                    allow="encrypted-media"
                />
            </div>

            <div>
                <a href="https://marcaempresa.com/" target="_blank">
                <img src= {baseURL+"assets/img/web/"+imgMarca+'?v='+Math.random()} style={{width:'100%',}} />
                </a>
         
          
            </div>
         
            
            <Carrusel auspiciadores={data.a1} />
            <Carrusel auspiciadores={data.a2} />
            <Carrusel auspiciadores={data.a3} />
            <Carrusel auspiciadores={data.a4} />
            <Carrusel auspiciadores={data.a5} />
           </div>
        </div>
        </Hidden>
       
       </>
      
    )
}

export default Auspiciadores