import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Typography } from '@material-ui/core';
import { errorAlerta, Success } from './alertas/Alertas';
import api from '../axios/api';
const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
export default function FormEmail(props) {
  const [open, setOpen] = React.useState(false);
 const [val,setVal]= React.useState({
    email:"",
telefono:"",
artista:"",
mensaje:"",
 })
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const validarEmail=(email)=>{
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
        return true
    }else{
        return false
    }
  }
  const enviar=()=>{
    if (props.ranking){
      setVal({
        ...val,
        mensaje: "Ranking : "+ val.mensaje
      })
    
    }
   
    if(val.email !== "" && val.mensaje !==""){
        if(validarEmail(val.email)){
            api.post('api/email/',val)
            .then(resp=>{
                Success("Se envio correctamente")
                setVal({ email:"",               
                mensaje:"",})
                handleClose()
            }).catch(error=>{
                errorAlerta("No se pudo enviar, intentelo nuevamente!!")
            })
        }else{
           errorAlerta("El email ingresano no es valido!!")
        }
    }else{
        errorAlerta("Todos los campos son obligatorios!!")
    }
  }
  const cambioSelect=(e)=>{
    setVal({
        ...val,
        [e.target.name]: e.target.value
    })
}
  return (
    <>
      
      <Button color="inherit"  onClick={handleClickOpen}>CONTACTO</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        
      >
        <DialogTitle style={{backgroundColor:props.color.primario, textAlign:"center"}} className="py-2">{"Envianos tu Consulta"}</DialogTitle>
        <DialogContent style={{backgroundColor:props.color.primario, padding:"2rem"}}>
          <TextField fullWidth  color="secondary"  margin="normal" name="email" value={val.email} label="E-MAIL" type="email" onChange={(e)=>cambioSelect(e)} />
        
          <TextField fullWidth  color="secondary"  margin="normal" name="mensaje" value={val.mensaje} label="MENSAJE"  onChange={(e)=>cambioSelect(e)}/>
          <Button fullWidth  variant="outlined" className="py-2" onClick={()=>enviar()}>Enviar</Button>
          <div className="py-2">
          <DialogTitle style={{backgroundColor:props.color.primario}} className="py-2">{"Tambien puedes contactarnos en:"}</DialogTitle>
          <Typography variant={"body2"} component={"p"} color={"secondary"}>Email: <a className="wnumd" href={"mailto:"+props.contacto.email}>{props.contacto.email}</a></Typography>
          <Typography variant={"body2"} component={"p"} color={"secondary"}>Teléfono: <a className="wnumd" href={"tel:"+props.contacto.telefonos}> (+591) {props.contacto.telefonos}</a> </Typography>
          <Typography variant={"body2"} component={"p"} color={"secondary"}>Whatssap: <a className="wnumd" href={"https://wa.me/"+props.contacto.fax+"?text=RadioCiudad"}  target="blank"> (+591) {props.contacto.fax}</a></Typography>
          </div>
        </DialogContent>
       
      </Dialog>
    </>
  );
}