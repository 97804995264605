import React, { Component } from "react";
import {
  Grid,
  Container,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import Subtitulo from "../../components/Subtitulo";
import Carousel from "react-elastic-carousel";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import FormSugerencia from "../../components/formSugerencia";
import imgUrl from "../../axios/urlimg";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
import baseURL from "../../axios/url";
import api from "../../axios/api";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 1280, itemsToShow: 3 },
];
const breakPoints2 = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1280, itemsToShow: 4 },
];

class MusicSections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      player: false,
      ide: 0,
      src: "",
      rank: 5,
    };
    this.playAudio = React.createRef();
    this.iniciar = this.iniciar.bind(this);
    this.pausar = this.pausar.bind(this);
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  iniciar = (audio, ide) => {
    if (this.state.player) {
      this.pausar();
    } else {
      this.setState({ player: true, src: audio, ide: ide });
      this.sleep(2000).then(() => {
        this.playAudio.src = this.state.src;
        this.playAudio.play();
      });
      api.get("api/sumarvision/" + ide).then((response) => {
        console.log(":)");
      });
    }
  };

  pausar = () => {
    this.setState({ ...this.state, player: false, src: "", ide: 0 });
    this.playAudio.src = this.state.src;
    this.playAudio.pause();
  };
  urlShare = baseURL + "cancion/hechobol/";
  urlnotop = baseURL + "cancion/notop/";
  urlrec = baseURL + "cancion/ciudadrec/";
  noticeShare = baseURL + "cancion/noticia/";
  render() {
      
    return (
      <>
       <Grid
          container
          className="content-full img-ini gris-trans py-2 "
          style={{
            marginTop: "30px",
            borderRight: "solid 35px " + this.props.color.secundario,
            background:'#eeeeee',
            backgroundAttachment: "fixed",
          }}
        >
          <Grid item xs={12} md={9} lg={9}>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12} md={6} lg={4} >
                  <Subtitulo
                    {...this.props}
                    text="NOTICIAS"
                    subText="Novedades en la farandula"
                  />
                </Grid>
                <Grid item xs={12} container spacing={2} style={{marginTop:20}} >
                <Carousel
                    breakPoints={breakPoints2}
                    onResize={(currentBreakPoint) =>
                      JSON.stringify(currentBreakPoint)
                    }
                    disableArrowsOnEnd={true}
                  >
                    {this.props.noticias &&
                      this.props.noticias.map((item, id) => (
                        
                          <Card key={id} style={{margin:5}}>
                          <CardActionArea
                            className="action"
                            component="a" href={"/noticia/"+item.id}
                          >
                            <CardMedia
                              style={{ height: 200, width: "auto" }}
                              image={ baseURL+"assets/img/noticias/"+item.img }
                              title={item.title}
                            >
                              
                            </CardMedia>
                          </CardActionArea>

                          <CardContent
                            style={{ background: '#fff' }}
                            className="cont"
                          >
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              component="h2"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: '#000',
                                textTransform: "uppercase",
                                paddingRight: 20,
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="body2"
                              component="p"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: '#000',
                                textTransform: "uppercase",
                              }}
                            >
                              {item.titular}
                            </Typography>
                            <div className="share-container" style={{ justifyContent: "center" }}>
                              <div className="share-item">
                                <FacebookShareButton
                                  url={this.noticeShare + item.id}
                                  quote={item.title}
                                  className="share-button"
                                >
                                  <FacebookIcon size={32} borderRadius={10} />
                                </FacebookShareButton>
                              </div>
                              <div className="share-item">
                                <TwitterShareButton
                                  url={this.noticeShare + item.id}
                                  quote={item.title}
                                  className="share-button"
                                >
                                  <TwitterIcon size={32} borderRadius={10} />
                                </TwitterShareButton>
                              </div>
                              <div className="share-item">
                                <WhatsappShareButton
                                  url={this.noticeShare + item.id}
                                  quote={item.title}
                                  separator=":: "
                                  className="share-button"
                                >
                                  <WhatsappIcon size={32} borderRadius={10} />
                                </WhatsappShareButton>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                         
                      ))}
                 </Carousel>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
        <Grid
          container
          className="content-full img-ini gris-trans "
          style={{
            marginTop: "30px",
            borderLeft: "solid 35px " + this.props.color.secundario,
            background: this.props.color.texto,
          }}
        >
          <audio ref={(ref) => (this.playAudio = ref)} />

          <Grid item xs={12} md={9} lg={9}>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12} md={6} lg={4} className="py-2">
                  <Subtitulo
                    {...this.props}
                    text=" OTROS EXITOS"
                    subText="Éxitos que no suenan en Ciudad"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignContent="center"
                  alignItems="center"
                  style={{ paddingTop: "1rem" }}
                >
                  <Typography variant="subtitle1" component="p" color="primary">
                    Canciones que por el formato o estilo de música (Cumbia,
                    Regueton, etc) no forman parte de nuestra programación, pero
                    ciudad los reconoce como exitos.
                  </Typography>
                </Grid>
                <Grid item xs={12} className="py-2">
                  <Carousel
                    breakPoints={breakPoints}
                    onResize={(currentBreakPoint) =>
                      JSON.stringify(currentBreakPoint)
                    }
                    disableArrowsOnEnd={true}
                  >
                    {this.props.reproductor.noPop &&
                      this.props.reproductor.noPop.map((item, id) => (
                        <Card key={id} className="card-music">
                          <CardActionArea
                            className="action"
                            onClick={() =>
                              this.iniciar(item.sources.mp3, item.id)
                            }
                          >
                            <CardMedia
                              style={{ height: 200, width: "auto" }}
                              image={item.poster}
                              title={item.artist}
                            >
                              <div className="audio-action">
                                {this.state.ide !== item.id ? (
                                  <PlayArrowIcon
                                    style={{ fontSize: 60, color: "#fff" }}
                                  />
                                ) : (
                                  <PauseIcon
                                    style={{ fontSize: 60, color: "#fff" }}
                                  />
                                )}
                              </div>
                            </CardMedia>
                          </CardActionArea>
                          <CardContent
                            style={{ background: this.props.color.secundario }}
                            className="cont"
                          >
                            <Typography
                              gutterBottom
                              color="primary"
                              variant="subtitle1"
                              component="h2"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              gutterBottom
                              color="primary"
                              variant="body2"
                              component="p"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.artist}
                            </Typography>

                            <div className="share-container">
                              <div className="share-item">
                                <FacebookShareButton
                                  url={this.urlnotop}
                                  quote={
                                    "Éxitos que no suenan en Radio Ciudad."
                                  }
                                  className="share-button"
                                >
                                  <FacebookIcon size={32} borderRadius={10} />
                                </FacebookShareButton>
                              </div>
                              <div className="share-item">
                                <TwitterShareButton
                                  url={this.urlnotop}
                                  quote={
                                    "No top ten, Canciones populares que no estan en nuestra programación"
                                  }
                                  className="share-button"
                                >
                                  <TwitterIcon size={32} borderRadius={10} />
                                </TwitterShareButton>
                              </div>
                              <div className="share-item">
                                <WhatsappShareButton
                                  url={this.urlnotop}
                                  quote={
                                    "No top ten, Canciones populares que no estan en nuestra programación"
                                  }
                                  separator=":: "
                                  className="share-button"
                                >
                                  <WhatsappIcon size={32} borderRadius={10} />
                                </WhatsappShareButton>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                  </Carousel>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>

        <Grid
          container
          className="content-full img-ini gris-trans py-2 "
          style={{
            marginTop: "30px",
            borderRight: "solid 35px " + this.props.color.secundario,
            backgroundImage:
              "linear-gradient(rgba(238, 238, 238, 0.63),rgba(238, 238, 238, 0.63)), url('" +
              imgUrl +
              this.props.image.background +
              "')",
            backgroundAttachment: "fixed",
          }}
        >
          <Grid item xs={12} md={9} lg={9}>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                  <Subtitulo
                    {...this.props}
                    text="HECHO EN BOLIVIA"
                    subText="Canciones de artistas Bolivianos"
                  />
                </Grid>
                <Grid item xs={12} className="py-2">
                  <Carousel
                    breakPoints={breakPoints}
                    onResize={(currentBreakPoint) =>
                      JSON.stringify(currentBreakPoint)
                    }
                    disableArrowsOnEnd={true}
                  >
                    {this.props.reproductor.hechoBol &&
                      this.props.reproductor.hechoBol.map((item, id) => (
                        <Card key={id} className="card-music">
                          <CardActionArea
                            className="action"
                            onClick={() =>
                              this.iniciar(item.sources.mp3, item.id)
                            }
                          >
                            <CardMedia
                              style={{ height: 200, width: "auto" }}
                              image={item.poster}
                              title={item.artist}
                            >
                              <div className="audio-action">
                                {this.state.ide !== item.id ? (
                                  <PlayArrowIcon
                                    style={{ fontSize: 60, color: "#fff" }}
                                  />
                                ) : (
                                  <PauseIcon
                                    style={{ fontSize: 60, color: "#fff" }}
                                  />
                                )}
                              </div>
                            </CardMedia>
                          </CardActionArea>

                          <CardContent
                            style={{ background: this.props.color.primario }}
                            className="cont"
                          >
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              component="h2"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: this.props.color.texto,
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="body2"
                              component="p"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: this.props.color.texto,
                              }}
                            >
                              {item.artist}
                            </Typography>
                            <div className="share-container">
                              <div className="share-item">
                                <FacebookShareButton
                                  url={this.urlShare + item.id}
                                  quote={item.title}
                                  className="share-button"
                                >
                                  <FacebookIcon size={32} borderRadius={10} />
                                </FacebookShareButton>
                              </div>
                              <div className="share-item">
                                <TwitterShareButton
                                  url={this.urlShare + item.id}
                                  quote={item.title}
                                  className="share-button"
                                >
                                  <TwitterIcon size={32} borderRadius={10} />
                                </TwitterShareButton>
                              </div>
                              <div className="share-item">
                                <WhatsappShareButton
                                  url={this.urlShare + item.id}
                                  quote={item.title}
                                  separator=":: "
                                  className="share-button"
                                >
                                  <WhatsappIcon size={32} borderRadius={10} />
                                </WhatsappShareButton>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                  </Carousel>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>

        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              md={10}
              lg={11}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={8}>
                <Card
                  style={{
                    backgroundImage: "url(/sugerencia.png)",
                    border: "solid 3px " + this.props.color.primario,
                  }}
                  className="suge-art"
                >
                  <CardContent className="vot-ah">
                    <div className="cont">
                      <Typography variant="h3" align="center">
                        SUGIERENOS UN
                      </Typography>
                      <Typography variant="h5" align="center">
                        ARTISTA NACIONAL
                      </Typography>

                      <FormSugerencia {...this.props} />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Grid
          container
          className=" py-2 "
          style={{
            marginTop: "30px",
            borderLeft: "solid 35px " + this.props.color.secundario,
            background: this.props.color.primario,
            backgroundImage: "url(/rdaud.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "600px auto",
            backgroundPosition: "left bottom",
          }}
        >
          <Grid item xs={12} md={9} lg={9}>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                  <Subtitulo {...this.props} text="CIUDAD EN EL RECUERDO" />
                </Grid>
                <Grid item xs={12} className="py-2">
                  <Carousel
                    breakPoints={breakPoints}
                    onResize={(currentBreakPoint) =>
                      JSON.stringify(currentBreakPoint)
                    }
                    disableArrowsOnEnd={true}
                  >
                    {this.props.reproductor.ciudadRec &&
                      this.props.reproductor.ciudadRec.map((item, id) => (
                        <Card key={id} className="card-music">
                          <CardActionArea
                            className="action"
                            onClick={() =>
                              this.iniciar(item.sources.mp3, item.id)
                            }
                          >
                            <CardMedia
                              style={{ height: 200, width: "auto" }}
                              image={item.poster}
                              title={item.artist}
                            >
                              <div className="audio-action">
                                {this.state.ide !== item.id ? (
                                  <PlayArrowIcon
                                    style={{ fontSize: 60, color: "#fff" }}
                                  />
                                ) : (
                                  <PauseIcon
                                    style={{ fontSize: 60, color: "#fff" }}
                                  />
                                )}
                              </div>
                            </CardMedia>
                          </CardActionArea>
                          <CardContent
                            style={{ background: this.props.color.texto }}
                            className="cont"
                          >
                            <Typography
                              gutterBottom
                              color="primary"
                              variant="subtitle1"
                              component="h2"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              gutterBottom
                              color="primary"
                              variant="body2"
                              component="p"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.artist}
                            </Typography>

                            <div className="share-container">
                              <div className="share-item">
                                <FacebookShareButton
                                  url={this.urlrec + item.id}
                                  quote={item.title}
                                  className="share-button"
                                >
                                  <FacebookIcon size={32} borderRadius={10} />
                                </FacebookShareButton>
                              </div>
                              <div className="share-item">
                                <TwitterShareButton
                                  url={this.urlrec + item.id}
                                  quote={item.title}
                                  className="share-button"
                                >
                                  <TwitterIcon size={32} borderRadius={10} />
                                </TwitterShareButton>
                              </div>
                              <div className="share-item">
                                <WhatsappShareButton
                                  url={this.urlrec + item.id}
                                  quote={item.title}
                                  separator=":: "
                                  className="share-button"
                                >
                                  <WhatsappIcon size={32} borderRadius={10} />
                                </WhatsappShareButton>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                  </Carousel>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default MusicSections;

/**
 *
 */
