import React, { Component } from 'react'
import api from '../../axios/api'
import { connect } from 'react-redux'
import { MuiThemeProvider, createMuiTheme, Typography, Grid, Container, Paper } from '@material-ui/core'
import NavBar from '../../components/NavBar'
import imgUrl from '../../axios/urlimg'
import Iframe from 'react-iframe'
import Subtitulo from '../../components/Subtitulo'
import baseURL from '../../axios/url'
import Footer from '../home/footer'

class Somos extends Component {
    componentDidMount() {
        this.cargar()
    }
    cargar() {
        if (!this.props.config.equipo) {
            this.props.iniciarLoad()
            api.get('api/all/')
                .then(response => {
                    this.props.cargarConfig(response.data)
                }).catch(error => {

                })
                .finally(() => {
                    this.props.terminarLoad()
                })
        }

    }
    render() {
        const dat = this.props.config

        if (!dat.equipo) {
            return <div className="loader">Cargando...</div>
        }
        const theme = createMuiTheme({
            typography:{
                fontFamily:[
                  'Montserrat', 'sans-serif'
                ].join(',')
              },
            palette: {
                primary: {
                    main: dat.color.primario
                },
                secondary: {
                    main: dat.color.secundario
                }
            }
        })
        return (
            <MuiThemeProvider theme={theme}>
                <NavBar {...this.props.config} />

                <div>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className="content-full img-ini gris-trans"
                        style={{ paddingTop: "6rem", backgroundImage: "linear-gradient(to right," + this.props.config.color.secundario + "," + this.props.config.color.secundario + "), url('" + imgUrl + this.props.config.image.background + "')" }}
                    >
                        <Grid item xs={12}>
                            <Container>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Subtitulo {...this.props.config} text="QUIENES SOMOS" />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Iframe src={this.props.config.url.youtube.somos}

                                            className="youtube face"
                                            frameborder="0"
                                            allowTransparency="true"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                        />



                                    </Grid>

                                    

                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className="content-full img-ini gris-trans"
                        style={{ paddingTop: "6rem", backgroundImage: "linear-gradient(to right," + this.props.config.color.secundario + "," + this.props.config.color.secundario + "), url('" + imgUrl + this.props.config.image.background + "')" }}
                   >
                        <Container>
                        <Paper className="mt-3">
                                            <Grid item xs={12} container>
                                                <Grid item xs={12} md={2} className="mt-3 p-1">
                                                    <img style={{
                                                        margin: 'auto',
                                                        display: 'block',
                                                        maxWidth: '100%',
                                                        maxHeight: '250px',
                                                    }} alt="" src={baseURL + 'assets/img/web/' + this.props.config.image.nosotros} />
                                                </Grid>
                                                <Grid item xs={12} md={10} className="mt-3 p-1">
                                                    <Typography component="h4" variant="h4" className="notice-title">Misión</Typography>
                                                    <Typography variant="body1" component="p" >
                                                        {this.props.config.somos.mision}
                                                    </Typography>
                                                    <Typography component="h4" variant="h4" className="notice-title">Visión</Typography>
                                                    <Typography variant="body1" component="p">
                                                        {this.props.config.somos.vision}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                        </Container>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className="content-full img-ini gris-trans"
                        style={{  backgroundImage: "linear-gradient(to right," + this.props.config.color.secundario + "," + this.props.config.color.secundario + "), url('" + imgUrl + this.props.config.image.background + "')", backgroundAttachment: "fixed" }}
                   >
                            <Container>
                                <Grid item xs={12} md={6} lg={4}>
                                        <Subtitulo {...this.props.config} text="NUESTRO EQUIPO" />
                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        justify="center"
                                        alignItems="center"
                                        className="mt-3">
                                        {
                                            this.props.config.equipo.gerente.map((row, i) => (
                                                <Paper key={i}>
                                                    <Grid key={row.id} container direction="column" justify="center" alignItems="center">
                                                        <img alt={row.nombres} src={baseURL + "assets/img/nosotros/" + row.img} className="avataripg" />
                                                        <Typography variant="h5" component="h2" className="notice-title">{row.nombres + ' ' + row.apellidos}</Typography>
                                                        <Typography variant="subtitle1" component="p">{row.cargo}</Typography>
                                                        <Typography variant="h6" component="p">"{row.informacion}"</Typography>

                                                    </Grid>
                                                </Paper>
                                            ))
                                        }

                                    </Grid>
                                    <Grid container justify="center" alignItems="center" spacing={6} className="mt-3">
                                        {
                                            this.props.config.equipo.equipo.map(row => (

                                                <Grid item key={row.id}>
                                                    <Paper className="p-1">
                                                        <Grid container direction="column" justify="center" alignItems="center">
                                                            <img alt={row.nombres} src={baseURL + "assets/img/nosotros/" + row.img} className="avatarip" />
                                                            <Typography variant="h5" component="h2" className="notice-title">{row.nombres}</Typography>
                                                            <Typography variant="h5" component="h2" className="notice-title">{row.apellidos}</Typography>
                                                            <Typography variant="subtitle1" component="p">{row.cargo}</Typography>

                                                        </Grid>
                                                    </Paper>
                                                </Grid>

                                            ))
                                        }


                                    </Grid>
                            </Container>
                    </Grid>
                </div>


               <Footer {...this.props.config}/>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.global.gen
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cargarConfig(main) {
            dispatch({
                type: 'CARGAR_MAIN',
                main
            })
        },
        iniciarLoad(main) {
            dispatch({
                type: 'INICIAR_LOAD',
                load: true
            })
        },
        terminarLoad(main) {
            dispatch({
                type: 'TERMINAR_LOAD',
                load: false
            })
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Somos)