import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const Success = (msj) =>{
    const MysWall = withReactContent(Swal)
    MysWall.fire({       
         type: 'success',
         icon: 'success',
         title:'Correcto!!',
         text: msj,
         showConfirmButton: false,
         timer: 1800,
         customClass:{
           container:"swal-alertip"
         }
         
       })
}
export const errorAlerta=(mensaje)=>{
    const MysWall = withReactContent(Swal)
      MysWall.fire({     
         type: 'error',
         icon: 'error',
         title:'Ubo un error!!',
         text: mensaje,
         showConfirmButton: false,
         timer: 1800,
         customClass:{
          container:"swal-alertip"
        }
         
       })
     }

export const AlertaBasica = (mensaje)=>{
    const MysWall = withReactContent(Swal)
    MysWall.fire(mensaje)
}

export const formError = (data)=>{
  const MysWall = withReactContent(Swal)

      MysWall.fire({       
        position: 'center',
        icon: 'error',
        title: 'Ooops...',
        html:data,
        showConfirmButton: false,
        timer: 2000,
        customClass:{
          container:"swal-alertip"
        }
       })
}

export const AlertaToken=()=>{
  Swal.queue([{
    title: 'Su cuenta expiró!',
    confirmButtonText: 'Aceptar',
    text:
      'Debe inciar sesión con una cuenta valida',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return window.location = '/login';
    }
  }])
}
export const AlertaModulo=()=>{
  Swal.queue([{
    title: 'No tiene permisos para usar este modulo!',
    confirmButtonText: 'Aceptar',
    text:
      'Comuniquese con su administrador',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return window.history.back();
    }
  }])
}

