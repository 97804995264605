import React from 'react'
import { Typography } from '@material-ui/core'

const Subtitulo =(props)=>{
    return(
        <div className="subtitle" style={{backgroundColor: props.color.secundario, color:props.color.primario}}>
            <Typography  component="h3" variant="h5" >{props.text}</Typography>
           {
               props.subText &&  <Typography variant="subtitle2"> {props.subText}</Typography>
           }
        </div>
    )
}

export default Subtitulo