const global={
    load:false,
    gen:{
        color:{},
        url:{},
        image:{},
        radio: "rigth",
        social: "left",
        reproductor:{
            ranking:[],
            noPop:[],
            hechoBol:[],
            ciudadRec:[],
            favorita:[]
        },
        contacto:{},
        auspiciadores:{}
    }
  

}
function reducer (state = global, action){
    switch(action.type){
        case 'CARGAR_MAIN':{
            return{
                ...state,
               gen:action.main
            }
        }
        case 'INICIAR_LOAD':{
            return{
                ...state,
                load:true
            }
        }
        case 'TERMINAR_LOAD':{
            return{
             ...state,
             load:false
            }
         }
         case 'LOAD_CLOSE':{
            
             return{
                 ...state,
                 load:false
                }
         }case 'RANK_VOTO_NOMBRE':{
            return{
                ...state,
                rankNombre:action.valor
            }
        }
        case 'RANK_VOTO_NUMERO':{
            return{
                ...state,
                rankNumero:action.valor
            }
        }
        case 'ACTUALIZAR_HISTORIA':{
            return{
                ...state,
                historia:action.histo
            }
        }
        default:
            return state;
    }
}
export default reducer;