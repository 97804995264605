import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import "./";
import Reproductor from "../../component/reproductor";
import api from "../../axios/api";
import { connect } from "react-redux";
import NavBar from "../../components/NavBar";
import Auspiciadores from "../../component/Auspiciadores";
import MusicSection from "./MusicSection";
import Footer from "./footer";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSong: "",
    };
  }

  componentDidMount() {
    this.cargar();
  }
  cargar() {
    this.props.iniciarLoad();
    api
      .get("api/all/")
      .then((response) => {
        this.props.cargarConfig(response.data);
      })
      .catch((error) => {})
      .finally(() => {
        this.props.terminarLoad();
      });
  }
  imgSong = (img) => {
    this.setState({ ...this.state, imgSong: img });
  };
  render() {
    const dat = this.props.config;
    if (!dat.equipo) {
      return <div className="loader">Cargando...</div>;
    }

    const theme = createMuiTheme({
      background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      typography: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },
      palette: {
        primary: {
          main: dat.color.primario,
        },
        secondary: {
          main: dat.color.secundario,
        },
      },
    });
    return (
      <MuiThemeProvider theme={theme}>
        <NavBar {...this.props.config} />
        <Auspiciadores {...this.props.config} />
        <Reproductor {...this.props.config} imgSong={this.imgSong} />
        <MusicSection {...this.props.config} botar={true} />
        <Footer {...this.props.config} />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.global.gen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    cargarConfig(main) {
      dispatch({
        type: "CARGAR_MAIN",
        main,
      });
    },
    iniciarLoad(main) {
      dispatch({
        type: "INICIAR_LOAD",
        load: true,
      });
    },
    terminarLoad(main) {
      dispatch({
        type: "TERMINAR_LOAD",
        load: false,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
