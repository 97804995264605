import React, { Component } from 'react';
import { Grid,Typography, Card, CardContent, CardMedia, IconButton, Container } from '@material-ui/core';
import { connect } from "react-redux";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {Rating} from '@material-ui/lab';
import PauseIcon from '@material-ui/icons/Pause';

import Voto from './Voto';
import baseURL from '../../axios/url';
import imgUrl from '../../axios/urlimg';
import Subtitulo from '../Subtitulo';
class Musicagral extends Component{

    /////->
    constructor(props){
        super(props);
        this.state={
            player:false,
            ide:0,
            src:'',
            rank:5
        }
        this.playAudio = React.createRef();
        this.iniciar=this.iniciar.bind(this);
        this.pausar=this.pausar.bind(this);
        
    }

     sleep=(milliseconds)=>{
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    iniciar(audio,ide){
            this.setState({src:audio,ide:ide,player:true})
            this.sleep(2000).then(()=>{
                this.playAudio.src=this.state.src
                this.playAudio.play()
            })
           
    }
    pausar(audio,ide){
        this.setState({src:audio,ide:0,player:false})
        this.playAudio.src=this.state.src
        this.playAudio.pause()
    }
    /////<---



    componentDidMount=()=>{
       
        this.props.modificarMenu('musica');
     }

     

     render(){
        const urlShare= baseURL+"articulo/rk";
        const titleShare = '';
         return(
           <Grid
           className="img-ini gris-trans py-2 "
                    style={{ paddingTop: "4rem", backgroundImage: "linear-gradient(" + this.props.color.secundario + "," + this.props.color.secundario + "), url('" + imgUrl + this.props.image.background + "')", backgroundAttachment: "fixed"}}>
            <Container>
            <div className="bg " >
                
                <audio ref={ref=>(this.playAudio=ref)}/>
                <Grid item xs={12} container spacing={1}>
                    <Grid item  xs={12} md={6} lg={4}>
                        <Subtitulo {...this.props} text="VOTA POR TU CANCIÓN FAVORITA" />
                    </Grid>  
                    <Grid item xs={12}></Grid>                      
                {                           
                    this.props.reproductor.ranking.map(row=>(                                
                        <Grid item xs={12} md={4} xl={3} key={row.id} >
                            
                            <Card className="music-card" style={{padding:"1rem"}}>
                           
                                <CardContent className="music-card-child" style={{maxWidth:"20ch"}}>
                                    
                                    <Typography component="h5" variant="subtitle2" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{row.artist}</Typography>
                                    <Typography component="p" variant="subtitle2" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} color="textSecondary">
                                        {row.title}
                                    </Typography>
                                    
                                    <Rating 
                                        name="half-rating" 
                                        value={row.rank} 
                                        precision={0.5}                                                
                                        />
                                   
                                </CardContent>
                                <CardMedia className="music-card-childa">
                                    {
                                       
                                        this.state.ide !== row.id?(
                                            <IconButton
                                             onClick={()=>{
                                                this.iniciar(row.sources.mp3,row.id)
                                             }}
                                            >
                                            <PlayArrowIcon className="music-icon"/>
                                            
                                            </IconButton>) :(
                                            <IconButton 
                                            onClick={()=>(
                                                this.pausar(row.sources.mp3,row.id)
                                             )}
                                                >
                                            <PauseIcon className="music-icon"/>
                                            
                                            </IconButton>)
                                             
                                        
                                    }
                                    
                                    <Voto id={row.id} />
                                </CardMedia>
                            </Card>
                        </Grid>
                    
                    ))
                }
               
            
            </Grid>
           
            
    </div>
               </Container>
          </Grid>
         )
     }
}
const mapStateToProps =state=>{
    return{
       cancion:state.global.canciones
    }
  }
  const mapDispatchToProps = (dispatch)=>{
    return{
        cargarMusicagral(music){
            dispatch({
                type:'MUSICA_GRAL',
                music
            })
          },
          modificarMenu(valor){
            dispatch({
              type:'VALOR_MENU',
              valor
            })
          }
      
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(Musicagral)