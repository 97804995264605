import React, { Component } from 'react';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Hidden, Tooltip, Fab, Typography, Button } from '@material-ui/core';
import imgUrl from '../axios/urlimg';
import { Link } from 'react-router-dom';

class Radio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            player: false,
            playRadio: true
        }


        this.playAudio = React.createRef();


        this.iniciar = this.iniciar.bind(this);
        this.pausar = this.pausar.bind(this);
        this.iniciarRadio = this.iniciarRadio.bind(this)


    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    iniciarRadio = () => {
        this.sleep(2000)
        this.iniciar()
    }
    iniciar = () => {
        this.playAudio.current.play()
        this.setState({ player: true })
    }
    pausar = () => {
        this.playAudio.current.pause()
        this.setState({ player: false })
    }
    radio = () => {
        if (this.state.player) {

        } else {

        }
    }

    render() {
        const radioUrl = "http://streamingenbolivia.com/8002/stream"
        if (!this.props.style) {
            var radioAling = "float-center"

            if (this.props.radio === "left") {
                radioAling = "float-center"
            }
        }

        return (
            <>
               
                <Hidden mdUp>
                    {
                        this.state.player ? <BottomNavigationAction onClick={this.pausar} label="91.3" icon={<PauseIcon />} selected /> : <BottomNavigationAction onClick={this.iniciar} label="91.3" icon={<PlayArrowIcon />} />
                    }

                </Hidden>

                <Hidden smDown>
                 
                        {
                            this.state.player ?
                                <Button
                                    component="a"
                                    color="inherit"
                                    onClick={this.pausar}
                                >
                                    <PauseIcon style={{ fontSize: "2rem", paddingLeft: "1rem", paddingRight: "1rem" }} />

Click Radio en Vivo
                                </Button>
                                :
                                <Button
                                    component="a"
                                    color="inherit"
                                    onClick={this.iniciar}
                                >
                                    <PlayArrowIcon style={{ fontSize: "2rem", paddingLeft: "1rem", paddingRight: "1rem" }} />
                     Click Radio en Vivo
                            </Button>
                        }


                </Hidden>
                <div><audio ref={this.playAudio} src={radioUrl} > </audio></div>
            </>
        )


    }
}
export default Radio


/*

if(this.state.player){

            return(
                <>
                 <div><audio ref={this.playAudio} src={radioUrl} autoPlay> </audio></div>
                <Hidden mdUp>
                <BottomNavigationAction onClick={this.pausar} label="91.3" icon={<PauseIcon />} selected/>

                </Hidden>

                <Hidden smDown>
                    <div>
                    <Tooltip title="Detener" aria-label="detener">
                        <Fab variant="extended" color="secondary"  className={"icon-radio "+radioAling} onClick={this.pausar} style={{color:this.props.color.primario }}>
                        <img  src={imgUrl+this.props.image.logo} alt="logo"/>
                            <PauseIcon style={{fontSize:"2rem", paddingLeft:"1rem", paddingRight:"1rem"}}/>
                            <div>
                            Click Radio en Vivo
                            <Typography> En el aire una amiga de verdad</Typography>
                            </div>
                        </Fab>
                    </Tooltip>


                    </div>
                </Hidden>
                </>
            )
        }else{

            return(
                <>
                 <div><audio ref={this.playAudio} src={radioUrl} autoPlay> </audio></div>
                <Hidden mdUp>
                <BottomNavigationAction onClick={this.iniciar} label="91.3" icon={<PlayArrowIcon/>} />

                </Hidden>
                <Hidden smDown>
                    <div>

                    <Tooltip title="Iniciar Radio en Vivo" aria-label="iniciar">
                        <Fab variant="extended" color="secondary" className={"icon-radio "+radioAling} style={{color:this.props.color.primario }} onClick={this.iniciar}>
                        <img  src={imgUrl+this.props.image.logo} alt="logo"/>
                            <PlayArrowIcon style={{fontSize:"2rem", paddingLeft:"1rem", paddingRight:"1rem"}}/>
                            <div>
                            Click Radio en Vivo
                            <Typography> En el aire una amiga de verdad</Typography>
                            </div>

                        </Fab>


                    </Tooltip>

                    </div>
                </Hidden>
                </>
            )
        }
*/