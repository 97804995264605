import React from 'react'
import { Grid, Typography, Container, Hidden } from '@material-ui/core'

const Footer=(props)=>{
    return(
       
      <div style={{background:props.color.secundario}}> 
          <Container >
              <div style={{paddingTop:"30px", paddingBottom:"30px"}}>
          <Typography variant={"body2"} component={"p"} color={"primary"}>Email: <a className="wnum" href={"mailto:"+props.contacto.email}>{props.contacto.email}</a></Typography>
          <Typography variant={"body2"} component={"p"} color={"primary"}>Teléfono: <a className="wnum" href={"tel:"+props.contacto.telefonos}> (+591) {props.contacto.telefonos}</a> </Typography>
          <Typography variant={"body2"} component={"p"} color={"primary"}>Whatssap: <a className="wnum" href={"https://wa.me/591"+props.contacto.fax+"?text=RadioCiudad"}  target="blank"> (+591) {props.contacto.fax}</a></Typography>
          <Typography variant={"body2"} component={"p"} style={{color:props.color.texto}}>Derechos Reservados 2020 Radio Ciudad - La Paz</Typography>
    
          </div>
         
          </Container>
      </div>
    )
}

export default Footer