import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import { blue } from '@material-ui/core/colors';
import { TextField, Typography } from '@material-ui/core';
import api from '../../axios/api';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  function handleClose() {
    onClose(selectedValue);
  }

  function handleListItemClick() {
    props.votarCancion();
  }
 
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Rellena los campos </DialogTitle>
      <Typography variant="subtitle1" align="center"> {selectedValue}</Typography>
      <List>
   
         <ListItem>
            <ListItemAvatar>
            <Avatar className={classes.avatar}>
                <PersonIcon />
            </Avatar>
            </ListItemAvatar>
            <TextField
                id="outlined-dense"
                label="Nombre"     
                name="nombre"
                onChange={props.cambio}            
                margin="dense"
                variant="outlined"
            />
        </ListItem>
        <ListItem>
            <ListItemAvatar>
            <Avatar className={classes.avatar}>
                <PhoneIcon/>
            </Avatar>
            </ListItemAvatar>
            <TextField
                id="outlined-dense"
                label="Nro Celular"
                name="numero"
                onChange={props.cambio}             
                margin="dense"
                type="number"
                variant="outlined"
            />
        </ListItem>

        <ListItem >
          <Button  onClick={() => handleListItemClick()}  
         
         
          >Enviar Voto</Button>
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

 function Voto({id}) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [val,setVal]= React.useState({nombre:"",numero:""})
  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = value => {
    ///mandar aki

    setOpen(false);
  
    
  };
   const cambio=(e)=> {
     setVal({...val,[e.target.name]: e.target.value})
    
 
}


const votarCancion=()=>{
    api.post('api/calificar/'+id,Object.assign({nombre:val.nombre,numero:val.numero})) 
  
    .then(response=>{
      if(response.data === true){       
     
        setOpen(false);
      }else{
        setSelectedValue("Solo puede votar una vez por canción!!")
        console.log(false)
      }
        
       })
       .catch(error=>{
         console.log(error)
       })
       .finally(function(){
     
       })
 }
  return (
    <div>
      
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Votar
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose}   votarCancion={ votarCancion } cambio= {cambio} val={val} />
    </div>
  );
}

  export default Voto