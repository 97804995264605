import React, { Component } from "react";
import "./reproductor.scss";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import PauseOutlinedIcon from "@material-ui/icons/PauseOutlined";
import SkipNextOutlinedIcon from "@material-ui/icons/SkipNextOutlined";

import SkipPreviousOutlinedIcon from "@material-ui/icons/SkipPreviousOutlined";
import { Grid, Container } from "@material-ui/core";
import Subtitulo from "../../components/Subtitulo";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
import baseURL from "../../axios/url";
class Reproductor extends Component {
  state = {
    index: 0,
    currentTime: "0:00",
    musicList: [
      {
        name: "Nice piano and ukulele",
        author: "Royalty",
        img: "https://www.bensound.com/bensound-img/buddy.jpg",
        duration: "1:00",
        sources: {
          mp3: "https://www.bensound.com/bensound-music/bensound-buddy.mp3",
        },
      },
    ],
    pause: false,
    duracion: "0:00",
  };

  componentDidMount() {
    this.playerRef.addEventListener("timeupdate", this.timeUpdate, false);
    this.playerRef.addEventListener("ended", this.nextSong, false);
    this.timelineRef.addEventListener("click", this.changeCurrentTime, false);
    this.timelineRef.addEventListener("mousemove", this.hoverTimeLine, false);
    this.timelineRef.addEventListener("mouseout", this.resetTimeLine, false);
    this.cargarMusica();
  }

  cargarMusica() {
    this.setState({
      ...this.state,
      musicList: this.props.reproductor.ranking,
    });
  }
  componentWillUnmount() {
    this.playerRef.removeEventListener("timeupdate", this.timeUpdate);
    this.playerRef.removeEventListener("ended", this.nextSong);
    this.timelineRef.removeEventListener("click", this.changeCurrentTime);
    this.timelineRef.removeEventListener("mousemove", this.hoverTimeLine);
    this.timelineRef.removeEventListener("mouseout", this.resetTimeLine);
  }

  changeCurrentTime = (e) => {
    const duration = this.playerRef.duration;

    const playheadWidth = this.timelineRef.offsetWidth;
    const offsetWidht = this.timelineRef.offsetLeft;
    const userClickWidht = e.clientX - offsetWidht;

    const userClickWidhtInPercent = (userClickWidht * 100) / playheadWidth;

    this.playheadRef.style.width = userClickWidhtInPercent + "%";
    this.playerRef.currentTime = (duration * userClickWidhtInPercent) / 100;
  };

  hoverTimeLine = (e) => {
    const duration = this.playerRef.duration;

    const playheadWidth = this.timelineRef.offsetWidth;

    const offsetWidht = this.timelineRef.offsetLeft;
    const userClickWidht = e.clientX - offsetWidht;
    const userClickWidhtInPercent = (userClickWidht * 100) / playheadWidth;

    if (userClickWidhtInPercent <= 100) {
      this.hoverPlayheadRef.style.width = userClickWidhtInPercent + "%";
    }

    const time = (duration * userClickWidhtInPercent) / 100;

    if (time >= 0 && time <= duration) {
      this.hoverPlayheadRef.dataset.content = this.formatTime(time);
    }
  };

  resetTimeLine = () => {
    this.hoverPlayheadRef.style.width = 0;
  };

  timeUpdate = () => {
    const duration = this.playerRef.duration;
    const playPercent = 100 * (this.playerRef.currentTime / duration);
    this.playheadRef.style.width = playPercent + "%";
    const currentTime = this.formatTime(parseInt(this.playerRef.currentTime));
    this.setState({
      currentTime,
    });
  };

  formatTime = (currentTime) => {
    const minutes = Math.floor(currentTime / 60);
    let seconds = Math.floor(currentTime % 60);

    seconds = seconds >= 10 ? seconds : "0" + (seconds % 60);

    const formatTime = minutes + ":" + seconds;

    return formatTime;
  };

  updatePlayer = () => {
    const { musicList, index } = this.state;
    const currentSong = musicList[index];
    this.playerRef.load();
  };

  nextSong = () => {
    const { musicList, index, pause } = this.state;

    this.setState({
      index: (index + 1) % musicList.length,
    });
    this.updatePlayer();
    if (pause) {
      this.playerRef.play();
    }
  };

  prevSong = () => {
    const { musicList, index, pause } = this.state;

    this.setState({
      index: (index + musicList.length - 1) % musicList.length,
    });
    this.updatePlayer();
    if (pause) {
      this.playerRef.play();
    }
  };

  playOrPause = () => {
    const { musicList, index, pause } = this.state;
    const currentSong = musicList[index];
    if (!this.state.pause) {
      this.playerRef.play();
    } else {
      this.playerRef.pause();
    }
    this.setState({
      pause: !pause,
    });
  };

  clickAudio = (key) => {
    const { pause } = this.state;

    this.setState({
      index: key,
    });

    this.updatePlayer();
    if (pause) {
      this.playerRef.play();
    }
  };

  render() {
    const { musicList, index, currentTime, pause } = this.state;
    const currentSong = musicList[index];
    const url = baseURL + "cancion/rank/";
    return (
      <div
        style={{ backgroundImage: "linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(" + currentSong.poster + ")"}}
        className="cont-rep"
      >
        <div
            className="cont"
          style={{
           height: "100%",
            width: "100%",
            
            paddingBottom: "5rem"
          }}
        >
          <Container>
            <Grid container style={{ marginBottom: "2rem" }}>
              <Grid item xs={12} md={6} lg={4}>
                <Subtitulo
                  {...this.props}
                  text="RANKING"
                  subText="Haz click en el boton Play del reproductor"
                />
              </Grid>
            </Grid>
          </Container>
          <Container>
            <Grid container className="card">
              <Grid item xs={12} md={10} lg={11} container>
                <Grid item xs={12} md={6} className="play-list">
                  {musicList.map((music, key = 0) => (
                    <div
                      key={key}
                      onClick={() => this.clickAudio(key)}
                      className={
                        "track " +
                        (index === key && !pause ? "current-audio" : "") +
                        (index === key && pause ? "play-now" : "")
                      }
                    >
                      <img className="track-img" src={music.poster} />
                      <div className="track-discr">
                        <span className="track-name">{music.title}</span>
                        <span className="track-author">{music.artist}</span>
                      </div>
                      <span className="track-duration">
                        {index === key ? (
                          currentTime
                        ) : (
                          <PlayArrowOutlinedIcon style={{ fontSize: 50 }} />
                        )}
                      </span>
                    </div>
                  ))}
                </Grid>

                <Grid className="current-song">
                  <audio ref={(ref) => (this.playerRef = ref)}>
                    <source src={currentSong.sources.mp3} type="audio/ogg" />
                    Your browser does not support the audio element.
                  </audio>
                  <div className="img-wrap">
                    <img src={currentSong.poster} alt="img" />
                  </div>
                  <span className="song-name">{currentSong.title}</span>
                  <span className="song-autor">{currentSong.artist}</span>

                  <div className="time">
                    <div className="current-time">{}</div>
                    <div className="end-time">{currentTime}</div>
                  </div>

                  <div ref={(ref) => (this.timelineRef = ref)} id="timeline">
                    <div
                      ref={(ref) => (this.playheadRef = ref)}
                      id="playhead"
                    ></div>
                    <div
                      ref={(ref) => (this.hoverPlayheadRef = ref)}
                      className="hover-playhead"
                      data-content="0:00"
                    ></div>
                  </div>

                  <div className="controls">
                    <button
                      onClick={this.prevSong}
                      className="prev prev-next current-btn"
                    >
                      <SkipPreviousOutlinedIcon />
                    </button>

                    <button
                      onClick={this.playOrPause}
                      className="play current-btn"
                    >
                      {!pause ? (
                        <PlayArrowOutlinedIcon fontSize="large" />
                      ) : (
                        <PauseOutlinedIcon fontSize="large" />
                      )}
                    </button>
                    <button
                      onClick={this.nextSong}
                      className="next prev-next current-btn"
                    >
                      <SkipNextOutlinedIcon />
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <div className="share-container" style={{ paddingTop: "2rem" }}>
            <div className="share-item">
              <FacebookShareButton
                url={url}
                quote="Ranking"
                className="share-button"
              >
                <FacebookIcon size={32} borderRadius={10} />
              </FacebookShareButton>
            </div>
            <div className="share-item">
              <TwitterShareButton
                url={url}
                quote="Ranking"
                className="share-button"
              >
                <TwitterIcon size={32} borderRadius={10} />
              </TwitterShareButton>
            </div>
            <div className="share-item">
              <WhatsappShareButton
                url={url}
                quote="Ranking"
                separator=":: "
                className="share-button"
              >
                <WhatsappIcon size={32} borderRadius={10} />
              </WhatsappShareButton>
            </div>
          </div>
          <Container>
            <Grid container>
              <Grid
                item
                xs={12}
                md={10}
                lg={11}
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <img src="/qr91.png" style={{ height: 150 }} />
                </Grid>
                <Grid item>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dribig.radio&hl=es&utm_source=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.dribig.radio%26hl%3Des&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    style={{
                      display: "inline-block",
                      overflow: "hidden",
                      borderRadius: 13,
                      width: 250,
                      height: 83,
                    }}
                  >
                    <img
                      alt="Disponible en Google Play"
                      src="./google-play-badge.png"
                      style={{ width: 250, height: 83 }}
                    />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://apps.apple.com/bo/app/radiociudad/id1593819117?itsct=apps_box_badge&amp;itscg=30200"
                    style={{
                      display: "inline-block",
                      overflow: "hidden",
                      borderRadius: 13,
                      width: 250,
                      height: 83,
                    }}
                  >
                    <img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/es-es?size=250x83&amp;releaseDate=1636588800&h=63441f4bd0d856acb920ff322b3e6d80"
                      alt="Download on the App Store"
                      style={{ borderRadius: 13, width: 250, height: 83 }}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}

export default Reproductor;
