import React, { Component } from "react";
import api from "../../axios/api";
import { connect } from "react-redux";
import {
  MuiThemeProvider,
  createMuiTheme,
  Grid,
  Container,
} from "@material-ui/core";
import NavBar from "../../components/NavBar";
import imgUrl from "../../axios/urlimg";
import Subtitulo from "../../components/Subtitulo";
import Footer from "../home/footer";
import ComponenteNoticia from "../../components/Noticias/ComponentNoticia";
class Noticias extends Component {
  componentDidMount() {
    this.cargar();
  }
  cargar() {
    if (!this.props.config.equipo) {
      this.props.iniciarLoad();
      api
        .get("api/all/")
        .then((response) => {
          this.props.cargarConfig(response.data);
        })
        .catch((error) => {})
        .finally(() => {
          this.props.terminarLoad();
        });
    }
  }
  render() {
    const dat = this.props.config;

    if (!dat.equipo) {
      return <div className="loader">Cargando...</div>;
    }
    const theme = createMuiTheme({
      typography: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },
      palette: {
        primary: {
          main: dat.color.primario,
        },
        secondary: {
          main: dat.color.secundario,
        },
      },
    });
    return (
      <MuiThemeProvider theme={theme}>
        <NavBar {...this.props.config} />

        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className="content-full img-ini gris-trans"
            style={{
              paddingTop: "6rem",
              backgroundImage:
                "linear-gradient(to right," +
                this.props.config.color.secundario +
                "," +
                this.props.config.color.secundario +
                "), url('" +
                imgUrl +
                this.props.config.image.background +
                "')",
            }}
          >
            <Grid item xs={12}>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Subtitulo {...this.props.config} text="NOTICIAS" />
                  </Grid>

                  <ComponenteNoticia noticia={dat.noticias} />
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </div>

        <Footer {...this.props.config} />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.global.gen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    cargarConfig(main) {
      dispatch({
        type: "CARGAR_MAIN",
        main,
      });
    },
    iniciarLoad(main) {
      dispatch({
        type: "INICIAR_LOAD",
        load: true,
      });
    },
    terminarLoad(main) {
      dispatch({
        type: "TERMINAR_LOAD",
        load: false,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Noticias);
