import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Ranking from "./pages/ranking";
import Somos from "./pages/somos";
import Home from "./pages/home";
import Noticias from "./pages/noticias";
import NoticiaDetail from "./components/Noticias/NoticiaDetail";

const Ruta = (props) => {
  const { component: Component, exact = false, path } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={(matchProps) => <Component {...matchProps} />}
    />
  );
};

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Ruta exact path="/" component={Home} />
          <Ruta exact path="/ranking" component={Ranking} />
          <Ruta exact path="/somos" component={Somos} />
          <Ruta exact path="/noticias" component={Noticias} />
          <Ruta exact path="/noticia/:id" component={NoticiaDetail} />

          <Route path="*" component={() => "En Contrucción..."} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;
