import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";
import { errorAlerta, Success } from "./alertas/Alertas";
import api from "../axios/api";
const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
export default function FormSugerencia(props) {
  const [open, setOpen] = React.useState(false);
  const [val, setVal] = React.useState({
    email: "",
    telefono: "",
    artista: "",
    mensaje: "",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const validarEmail = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const enviar = () => {
    if (props.ranking) {
      setVal({
        ...val,
        mensaje: "Ranking : " + val.mensaje,
      });
    }

    if (
      val.email !== "" &&
      val.telefono !== "" &&
      val.artista !== "" &&
      val.mensaje !== ""
    ) {
      if (validarEmail(val.email)) {
        api
          .post("api/sugerencia/", val)
          .then((resp) => {
            Success("Se envio correctamente");
            setVal({ email: "", telefono: "", artista: "", mensaje: "" });
            handleClose();
          })
          .catch((error) => {
            errorAlerta("No se pudo enviar, intentelo nuevamente!!");
          });
      } else {
        errorAlerta("El email ingresano no es valido!!");
      }
    } else {
      errorAlerta("Todos los campos son obligatorios!!");
    }
  };
  const cambioSelect = (e) => {
    setVal({
      ...val,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <Button
        variant="contained"
        color={props.ranking ? "secondary" : "primary"}
        onClick={handleClickOpen}
      >
        SUGERIR
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{ backgroundColor: props.color.primario, textAlign: "center" }}
          className="py-2"
        >
          {"CONTACTANOS"}
        </DialogTitle>
        <DialogContent
          style={{ backgroundColor: props.color.primario, padding: "2rem" }}
        >
          <TextField
            fullWidth
            color="secondary"
            margin="normal"
            name="email"
            value={val.email}
            label="E-MAIL"
            type="email"
            onChange={(e) => cambioSelect(e)}
          />
          <TextField
            fullWidth
            color="secondary"
            margin="normal"
            name="telefono"
            value={val.telefono}
            label="TELÉFONO"
            type="number"
            onChange={(e) => cambioSelect(e)}
          />
          <TextField
            fullWidth
            color="secondary"
            margin="normal"
            name="artista"
            value={val.artista}
            label="ARTISTA"
            onChange={(e) => cambioSelect(e)}
          />
          <TextField
            fullWidth
            color="secondary"
            margin="normal"
            name="mensaje"
            value={val.mensaje}
            label="MENSAJE"
            onChange={(e) => cambioSelect(e)}
          />
          <Button
            fullWidth
            variant="outlined"
            className="py-2"
            onClick={() => enviar()}
          >
            Enviar
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
