import React from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Hidden,
} from "@material-ui/core";
import MusicSections from "../incio/MusicSection";
import Carrusel from "../../components/Carrusel";
import FormSugerencia from "../../components/formSugerencia";

const MusicSection = (props) => {
  return (
    <div style={{ background: props.color.secundario, paddingTop: "20px" }}>
      <Container>
        {props.botar && (
          <Grid container>
            <Grid
              item
              xs={12}
              md={10}
              lg={11}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={8}>
                <Card
                  style={{ background: props.color.primario, width: "100%" }}
                  className="border-default"
                >
                  <CardContent className="vot-ah">
                    <div className="cont">
                      <Typography variant="h3" align="center">
                        SUGIERENOS
                      </Typography>
                      <Typography variant="h5" align="center">
                        UNA CANCIÓN PARA EL RANKING
                      </Typography>

                      <FormSugerencia {...props} ranking={true} />
                    </div>
                    <img src="/audifono.png" />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Hidden mdUp>
              <div className="ausp-sm">
                <Carrusel auspiciadores={props.auspiciadores} />
              </div>
            </Hidden>
          </Grid>
        )}
      </Container>
      <MusicSections {...props} />
    </div>
  );
};

export default MusicSection;
