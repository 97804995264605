import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Hidden } from "@material-ui/core";
import "./layaut.css";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";

import { Link } from "react-router-dom";
import Radio from "./Radio";
import imgUrl from "../axios/urlimg";
import FormEmail from "./emailForm";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const NavBar = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    //modificarMenu(newValue)

    setValue(newValue);
  };

  return (
    <>
      <Hidden mdUp>
        <BottomNavigation
          value={value}
          onChange={handleChange}
          showLabels
          className={classes.root + " fixedbutton"}
        >
          <BottomNavigationAction
            label="Inicio"
            value="inicio"
            icon={<HomeIcon />}
            href={"/"}
          />
          <Radio {...props} />
          <BottomNavigationAction
            label="Somos"
            value="somos"
            icon={<ViewHeadlineIcon />}
            href={"/somos"}
          />
        </BottomNavigation>
      </Hidden>
      <Hidden smDown>
        <div className="header">
          <header>
            <Grid container>
              <Grid item xs={10} sm={12} md={3} xl={5}>
                <img
                  className="logo-ini"
                  src={imgUrl + props.image.logo + "?v=54"}
                  alt="logo"
                />
              </Grid>
              <Grid item xs={8} sm={12} md={9} xl={7}>
                <div
                  className="nav"
                  style={{
                    background: props.color.primario,
                    borderRadius: "5em 0em 0em 5em",
                  }}
                >
                  <Button component={Link} color="inherit" to={"/"}>
                    inicio
                  </Button>
                  <Button component={Link} color="inherit" to={"/noticias"}>
                    noticias
                  </Button>

                  <Button component={Link} color="inherit" to={"/somos"}>
                    Quienes Somos
                  </Button>

                  <FormEmail {...props} />
                  <Radio {...props} />
                </div>
              </Grid>
            </Grid>
          </header>
        </div>
      </Hidden>
    </>
  );
};
export default NavBar;
