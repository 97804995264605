import React, { Component } from "react";
import api from "../../axios/api";
import { connect } from "react-redux";
import {
  MuiThemeProvider,
  createMuiTheme,
  Grid,
  Container,
  Paper,
  Typography,
} from "@material-ui/core";
import NavBar from "../../components/NavBar";
import imgUrl from "../../axios/urlimg";
import Footer from "../../pages/home/footer";
import baseURL from "../../axios/url";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
class NoticiaDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticia: null,
    };
  }
  componentDidMount() {
    this.cargar();
    this.cargarNoticia(this.props.match.params.id);
  }
  cargar() {
    if (!this.props.config.equipo) {
      this.props.iniciarLoad();
      api
        .get("api/all/")
        .then((response) => {
          this.props.cargarConfig(response.data);
        })
        .catch((error) => {})
        .finally(() => {
          this.props.terminarLoad();
        });
    }
  }
  cargarNoticia(ide) {
    api
      .get("api/noticia/" + ide)
      .then((response) => {
        this.setState({ noticia: response.data });
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(function () {});
  }

  render() {
    const dat = this.props.config;

    if (!dat.equipo) {
      return <div className="loader">Cargando...</div>;
    }
    const theme = createMuiTheme({
      typography: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },
      palette: {
        primary: {
          main: dat.color.primario,
        },
        secondary: {
          main: dat.color.secundario,
        },
      },
    });

    return (
      <MuiThemeProvider theme={theme}>
        <NavBar {...this.props.config} />

        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className="content-full img-ini gris-trans"
            style={{
              minHeight: "100vh",
              paddingTop: "6rem",
              backgroundImage:
                "linear-gradient(to right," +
                this.props.config.color.secundario +
                "," +
                this.props.config.color.secundario +
                "), url('" +
                imgUrl +
                this.props.config.image.background +
                "')",
            }}
          >
            <Grid item xs={12}>
              <Container>
              { this.state.noticia ?<Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper className="noti-view noti-comp">
                      <Typography
                        variant="h3"
                        component="h2"
                        style={{
                          color: this.props.color,
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                        align="center"
                      >
                        {this.state.noticia.titulo}
                      </Typography>
                      <div className="noti-img">
                        <img
                          src={
                            baseURL +
                            "assets/img/noticias/" +
                            this.state.noticia.img
                          }
                        />
                        <Typography variant="subtitle2" component="h4">
                          {this.state.noticia.fecha_noticia}
                        </Typography>
                      </div>
                      <div className="noti-body">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.noticia.descripcion,
                          }}
                        />
                      </div>
                      <div className="share-container">
                              <div className="share-item">
                                <FacebookShareButton
                                  url={baseURL+"cancion/noticia/" + this.state.noticia.id}
                                  quote={this.state.noticia.title}
                                  className="share-button"
                                >
                                  <FacebookIcon size={32} borderRadius={10} />
                                </FacebookShareButton>
                              </div>
                              <div className="share-item">
                                <TwitterShareButton
                                  url={baseURL+"cancion/noticia/" + this.state.noticia.id}
                                  quote={this.state.noticia.title}
                                  className="share-button"
                                >
                                  <TwitterIcon size={32} borderRadius={10} />
                                </TwitterShareButton>
                              </div>
                              <div className="share-item">
                                <WhatsappShareButton
                                  url={baseURL+"cancion/noticia/" + this.state.noticia.id}
                                  quote={this.state.noticia.title}
                                  separator=":: "
                                  className="share-button"
                                >
                                  <WhatsappIcon size={32} borderRadius={10} />
                                </WhatsappShareButton>
                              </div>
                            </div>
                    </Paper>
                  </Grid>
                </Grid>:
        <Typography style={{color:'#fff'}}>No existen datos para mostrar!!!</Typography>
        }
       
              </Container>
            </Grid>
          </Grid>
        </div>

        <Footer {...this.props.config} />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.global.gen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    cargarConfig(main) {
      dispatch({
        type: "CARGAR_MAIN",
        main,
      });
    },
    iniciarLoad(main) {
      dispatch({
        type: "INICIAR_LOAD",
        load: true,
      });
    },
    terminarLoad(main) {
      dispatch({
        type: "TERMINAR_LOAD",
        load: false,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NoticiaDetail);
