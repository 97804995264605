import React from 'react';
import styled from 'styled-components';
import ItemsCarousel from 'react-items-carousel';


const noOfCards = 1;
const autoPlayDelay = 3000;
const chevronWidth = 0;

const Wrapper = styled.div`
  padding: 0 ${chevronWidth}px;
  max-width: 1000px;
  margin: 0 auto;
`;



 class Carrusel extends React.Component {
  state = {
    activeItemIndex: 0,
  };
  
  componentDidMount() {
    this.interval = setInterval(this.tick, autoPlayDelay);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => this.setState(prevState => ({
    activeItemIndex: (prevState.activeItemIndex + 1) % (this.props.auspiciadores.length-noOfCards + 1),
  }));

  onChange = value => this.setState({ activeItemIndex: value });

  render() {  
   
    return (
      <Wrapper style={{paddingTop:"0.5rem"}}>
       
        <ItemsCarousel
          gutter={12}
          numberOfCards={1}
          slidesToScroll={2}
          activeItemIndex={this.state.activeItemIndex}
          requestToChangeActive={this.onChange}
          outsideChevron
        chevronWidth={chevronWidth}
          children={this.props.auspiciadores.map(index => (
              <a href={index.link} target="blank">
            <img
              className="carrusel-img"
              alt='img'
              src= {index.imagen}
            />
          </a>          
          ))
        }
        />
       
        
      </Wrapper>
    );
  }
}

export default Carrusel;